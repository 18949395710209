/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Rails
const Rails = require('rails-ujs');

Rails.start();

// Turbolinks
const Turbolinks = require('turbolinks');

Turbolinks.start();

// Bootstrap
import 'bootstrap/dist/js/bootstrap.min';
import 'bootstrap-datepicker/dist/js/bootstrap-datepicker.min';

// Images
require.context('../images', true);

// JavaScript initialization
import 'select2';
import '../../javascript/channels/import_status_channel';
import '../../javascript/channels/consumer';
import '../../javascript/invoice/invoice';

document.addEventListener('turbolinks:before-cache', () => {
  $('select, .select2-create, .select2-multi').select2('destroy');
});

/* eslint no-undef: 0 */
document.addEventListener('turbolinks:load', () => {
  $.fn.select2.defaults.set('theme', 'bootstrap4');
  $.fn.select2.defaults.set('width', '100%');

  $('.datepicker').datepicker();

  $('.datepickerWdateFormat').datepicker({
    format: 'yyyy-mm-dd',
  });

  $('select').select2();

  $('.select2-create').select2({ tags: true });

  $('.select2-multi').select2({ multiple: true });

  $('#house_campus_id').on('change', (e) => {
    const $selectedOption = $('option:selected', e.target);
    $('[id$="city"]').val($selectedOption.data('city'));
    $('[id$="state"]').val($selectedOption.data('state')).change();
    $('[id$="postal_code"]').val($selectedOption.data('postal-code')).change();
  });

  // For student and houses entity tab list results selections
  $('#checkAll').on('click', () => {
    const selec = document.getElementsByName('bulk_update[]');
    for (let i = 0; i < selec.length; i += 1) {
      if (selec[i].type === 'checkbox') selec[i].checked = true;
    }
    $('#checkAll').toggle();
    $('#uncheckAll').toggle();
    $('#bulkactions').show();
  });

  $('#uncheckAll').on('click', () => {
    const selec = document.getElementsByName('bulk_update[]');
    for (let i = 0; i < selec.length; i += 1) {
      if (selec[i].type === 'checkbox') selec[i].checked = false;
    }
    $('#checkAll').toggle();
    $('#uncheckAll').toggle();
    $('#bulkactions').hide();
  });


  $('input[type="checkbox"]').click(function checkboxesrows() {
    const $checkboxes = $('input[type="checkbox"]');
    const countCheckedCheckboxes = $checkboxes.filter(':checked').length;
    if ($(this).is(':checked')) {
      $('#bulkactions').show();
    } else if ($(this).is(':not(:checked)')) {
      console.log('Checkbox is unchecked.');
      if (countCheckedCheckboxes === 0) {
        $('#bulkactions').hide();
      }
    }
  });


  // For student entity tab list result submission but applies
  // for all that has this data subtmit on..
  $('select#doaction,select#assignee').on('change', function hiddenSubmit(event) {
    if ($('select#doaction,select#assignee').val() === '') { return; }
    if (window.confirm('Click OK to continue?')) { // eslint-disable-line no-alert
      $('#bulkloading').toggle();
      $(this).closest('form').submit();
      event.preventDefault();
    } else {
      $('select#doaction,select#assignee').val('').trigger('change');
      event.preventDefault();
    }
  });

  $('#q_status_eq').select2({
    placeholder: 'Status',
  });

  $('#q_start_date_eq').select2({
    placeholder: 'Start Date',
  });

  const getQueryParam = (param) => {
    const search = window.location.search.substring(1);
    const params = new URLSearchParams(search);
    return params.get(param);
  };

  const initHouseDropdownValue = () => {
    const houseId = getQueryParam('q[house_id_eq]');

    if (houseId) {
      $('#q_budget_house_id_eq').val(houseId);
    }
  };

  const initCampusHouseDependentDropdowns = ($campusDropdown, $houseDropdown, $unique) => {
    initHouseDropdownValue();
    $campusDropdown.on('change', () => {
      $houseDropdown.val('').change();
    });

    $houseDropdown.select2({
      minimumResultsForSearch: -1,
      ajax: {
        url: '/houses.json',
        data() {
          return {
            'q[campus_id_eq]': $campusDropdown.val(),
            'q[unique]': $unique,
          };
        },
        processResults: data => ({ results: data }),
      },
    });
  };

  initCampusHouseDependentDropdowns($('#chef_campus_id'), $('#chef_house_ids'), false);
  initCampusHouseDependentDropdowns($('#q_chef_houses_campus_id_eq'), $('#q_chef_houses_id_eq'), false);
  initCampusHouseDependentDropdowns($('#student_house_campus_id'), $('#student_house_id'), false);
  initCampusHouseDependentDropdowns($('#q_house_campus_id_eq'), $('#q_house_id_eq'), false);
  initCampusHouseDependentDropdowns($('#q_houses_campus_id_eq'), $('#q_houses_id_eq'), false);
  initCampusHouseDependentDropdowns($('#menu_campus_id'), $('#menu_house_ids'), false);
  initCampusHouseDependentDropdowns($('#q_student_house_campus_id_eq'), $('#q_student_house_id_eq'), false);
  initCampusHouseDependentDropdowns($('#campus'), $('#house'), false);
  initCampusHouseDependentDropdowns($('#q_budget_campus_id_eq'), $('#q_budget_house_id_eq'), true);

  const $roleSelect = $('#user_role');
  $roleSelect.on('change', () => {
    const $campusSelectContainer = $('#user_campus').parents('.form-group');
    if ($roleSelect.val() === 'campus_manager') {
      $campusSelectContainer.removeClass('d-none');
    } else {
      $campusSelectContainer.addClass('d-none');
    }
  });
  $roleSelect.change();

  function updateBudgetImportState() {
    const $yearSelect = $('#budget-import-start-year');
    const $fileInput = $('#budget-import-file-input');
    const $submitButton = $('#budget-import-submit');

    function checkInputs() {
      const yearSelected = $yearSelect.val() !== '';
      const fileSelected = $fileInput.val() !== '';
      $submitButton.prop('disabled', !(yearSelected && fileSelected));
      $submitButton.attr('class', yearSelected && fileSelected ? 'btn btn-lg btn-yellow' : 'btn btn-lg btn-secondary');
    }

    $yearSelect.change(checkInputs);
    $fileInput.change(checkInputs);
  }
  updateBudgetImportState();
});
