import consumer from './consumer';

function updateUI(data) {
  const importStatusCard = document.getElementById('importStatusCard');
  importStatusCard.style.display = 'block';
  // Update the progress bar
  const progressBar = document.getElementById('importProgressBar');
  if (progressBar) {
    // eslint-disable-next-line no-nested-ternary
    progressBar.className = data.status === 'Completed'
      ? 'progress-bar bg-success'
      : (data.status === 'Failed' ? 'progress-bar bg-danger' : 'progress-bar bg-primary');
    progressBar.style.width = `${data.progress}%`;
    progressBar.setAttribute('aria-valuenow', data.progress);
    progressBar.textContent = data.status === 'failed' ? 'FAILED' : `${data.progress}% Complete`;
  }

  const statusText = document.getElementById('importStatus');
  if (statusText) {
    statusText.textContent = `Status: ${data.status}`;
  }

  if (data.error) {
    const errorsContainer = document.getElementById('live-import-errors');
    if (!errorsContainer) {
      console.log('Error container not found!');
      return;
    }

    const errorElement = document.createElement('div');
    errorElement.className = 'alert alert-danger';
    if (data.row) {
      errorElement.textContent = `Error at row ${data.row}: ${data.error}`;
    } else {
      errorElement.textContent = `Error: ${data.error}`;
    }

    errorsContainer.appendChild(errorElement);
  }
}

consumer.subscriptions.create('ImportStatusChannel', {
  connected() {
    console.log('Connected to the Import Status Channel successfully!');
  },
  disconnected() {
    console.log('Disconnected from the Import Status Channel.');
  },
  received(data) {
    updateUI(data);
  },
  rejected() {
    console.log('Subscription rejected: Check that the channel name matches and that the server accepts the subscription.');
  },
});
