// Global or shared scope
function validateImages() {
  const $imageUpload = $('#image-upload');
  const hasNewImages = $imageUpload[0].files.length > 0;
  const hasExistingImages = $('.remove-existing-image').length > 0;
  let isValid = true;

  if (!hasNewImages && !hasExistingImages) {
    isValid = false;
  }

  return isValid;
}

function validateForm() {
  const $requiredFields = $('[data-required]');
  const $vendorName = $('[name="invoice[vendor_name]"]');
  const $submitButton = $('#submit-button');
  const $spendLocationSelect = $('#spend-location-select');
  const $invoiceAmount = $('#invoice-amount');
  const $invoiceVendorName = $('#invoice_vendor_name');

  let isValid = true;

  // Check required fields
  $requiredFields.each(function () {
    if (!$(this).val()) {
      isValid = false;
    }
  });

  if ($spendLocationSelect.val() === 'other_location') {
    $invoiceVendorName.attr('data-required', true);
    if (!validateImages()) {
      isValid = false;
    }

    if ($invoiceVendorName.val().trim() === '') {
      isValid = false;
    }
  } else {
    $invoiceVendorName.removeAttr('data-required');
  }

  // Validate invoice amount
  const parsedAmount = parseFloat($invoiceAmount.val().replace(/[^0-9.-]+/g, ""));
  if (isNaN(parsedAmount) || parsedAmount <= 0) {
    isValid = false;
  }

  // Update submit button state
  $submitButton.prop('disabled', !isValid);
  $submitButton.attr('class', isValid ? 'btn btn-lg btn-yellow' : 'btn btn-lg btn-secondary');
}

function updatePreview() {
  previewContainer.innerHTML = '';

  filesArray.forEach((file, index) => {
    const reader = new FileReader();

    reader.onload = function(e) {
      const colDiv = document.createElement('div');
      colDiv.className = 'col-5 position-relative p-0 m-2';

      const img = document.createElement('img');
      img.src = e.target.result;
      img.className = 'img-fluid img-thumbnail mb-4';

      const link = document.createElement('a');
      link.href = e.target.result;
      link.appendChild(img);

      const removeButton = document.createElement('button');
      removeButton.className = 'btn btn-danger btn-sm position-absolute';
      removeButton.innerText = '×';
      removeButton.onclick = function() {
        filesArray.splice(index, 1);
        updatePreview();
        updateFileInput();
        validateImages();
        validateForm();
      };

      colDiv.appendChild(link);
      colDiv.appendChild(removeButton);
      previewContainer.appendChild(colDiv);
    };

    reader.readAsDataURL(file);
  });
}

function validateVendorName() { 
  const $vendorName = $('[name="invoice[vendor_name]"]');
  const $invoiceVendorName = $('#invoice_vendor_name');
  const $spendLocationSelect = $('#spend-location-select');

  if ($spendLocationSelect.val() === 'other_location') {
    $invoiceVendorName.attr('data-required', true);
    if ($invoiceVendorName.val().trim() === '') {
      $vendorName.addClass('is-invalid');
    } else {
      $vendorName.removeClass('is-invalid');
    }
    $invoiceVendorName.on('input', validateForm);
  } else {
    $invoiceVendorName.removeAttr('data-required');
    $vendorName.removeClass('is-invalid is-valid');
    $invoiceVendorName.on('input', validateForm);
  }
}

function initializeToggleAndValidateInvoiceForm() {
  const $spendLocationSelect = $('#spend-location-select');
  const $vendorNameDiv = $('#vendor-name-div');
  const $imageButtonDiv = $('#image-button-div');
  const $requiredFields = $('[data-required]');
  const $imageUpload = $('#image-upload');

  function toggleFields() {
    if ($spendLocationSelect.val() === 'other_location') {
      $vendorNameDiv.show();
      $imageButtonDiv.show();
    } else {
      $vendorNameDiv.hide();
      $imageButtonDiv.hide();
    }
  }

  if ($spendLocationSelect.length) {
    $spendLocationSelect.on('change', toggleFields);
    $spendLocationSelect.on('change', validateForm);
  }

  $requiredFields.on('input', validateForm);
  $imageUpload.on('change', validateForm);

  validateForm();
  toggleFields();
}

function initializeImageUpload() {
  const imageUpload = document.getElementById('image-upload');
  const addImagesButton = document.getElementById('add-images-button');
  const previewContainer = document.getElementById('new-preview-container');
  let filesArray = [];

  if (!imageUpload || !addImagesButton || !previewContainer) return;

  addImagesButton.addEventListener('click', function() {
    imageUpload.click();
  });

  imageUpload.addEventListener('change', function() {
    filesArray = filesArray.concat(Array.from(this.files));
    updatePreview();
    updateFileInput();
  });

  function updatePreview() {
    previewContainer.innerHTML = '';

    filesArray.forEach((file, index) => {
      const reader = new FileReader();

      reader.onload = function(e) {
        const colDiv = document.createElement('div');
        colDiv.className = 'col-5 position-relative p-0 m-2';

        const img = document.createElement('img');
        img.src = e.target.result;
        img.className = 'img-fluid img-thumbnail mb-4';

        const link = document.createElement('a');
        link.href = e.target.result;
        link.appendChild(img);

        const removeButton = document.createElement('button');
        removeButton.className = 'btn btn-danger btn-sm position-absolute';
        removeButton.innerText = '×';
        removeButton.onclick = function() {
          filesArray.splice(index, 1);
          updatePreview();
          updateFileInput();
          validateImages();
        };

        colDiv.appendChild(link);
        colDiv.appendChild(removeButton);
        previewContainer.appendChild(colDiv);
      };

      reader.readAsDataURL(file);
    });
  }

  function updateFileInput() {
    const submitButton = document.getElementById('submit-button');
    const dataTransfer = new DataTransfer();

    filesArray.forEach(file => {
      dataTransfer.items.add(file);
    });

    imageUpload.files = dataTransfer.files;

    if (imageUpload.files.length === 0) {
      submitButton.disabled = true;
      submitButton.classList.remove('btn-yellow');
      submitButton.classList.add('btn-secondary');
    }
  }
}

function handleCurrencyInput() {
  const currencyInput = document.querySelector('.currency-input');
  const hiddenInput = document.querySelector('.hidden-currency-input');

  currencyInput.addEventListener('input', function() {
    let value = currencyInput.value.replace(/[^0-9.]/g, '');
    if (value) {
      currencyInput.value = `$${value}`;
      hiddenInput.value = value;
    } else {
      hiddenInput.value = '';
    }
  });
  if (currencyInput.value) {
    let value = currencyInput.value.replace(/[^0-9.]/g, '');
    currencyInput.value = `$${parseFloat(value).toFixed(2)}`;
    hiddenInput.value = value;
  }
}

function initializeExistingImageRemoval() {
  const previewContainer = document.getElementById('existing-preview-container');
  const removedImagesField = document.getElementById('removed_images');

  previewContainer.addEventListener('click', function(event) {
    if (event.target.classList.contains('remove-existing-image')) {
      const imageId = event.target.dataset.imageId;
      if (imageId) {
        let removedImages = removedImagesField.value ? removedImagesField.value.split(',') : [];
        removedImages.push(imageId);
        removedImagesField.value = removedImages.join(',');
      }
      event.target.parentElement.remove();
      validateForm();
    }
  });
}

document.addEventListener('turbolinks:load', () => {
  initializeToggleAndValidateInvoiceForm();
  handleCurrencyInput();
  initializeImageUpload();
  initializeExistingImageRemoval();
  validateVendorName();
});
